#app-container {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 575px) {
  #app-container {
    align-items: stretch;
  }
}

#background-filter {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  -webkit-backdrop-filter: blur(0px);
  backdrop-filter: blur(0px);
}
#background-filter.active {
  display: inline;
  z-index: 1;
  -webkit-backdrop-filter: blur(4px);
  backdrop-filter: blur(4px);
}