#restart-confirm-modal {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 2;
  transform: translate(-50%, -50%);
  background: #F8F8F8;
  border-radius: 0.4rem;
  box-shadow: -4px 4px 4px #26282B;
  box-sizing: border-box;
  padding: 1rem;
  text-align: center;
  font-size: 1.5rem;
}
@media only screen and (max-width: 575px) {
  #restart-confirm-modal {
    width: 90%;
    padding: 0.5rem;
  }
}

#restart-confirm-text {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

#restart-confirm-modal-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 100%;
}

#restart-confirm-yes-btn {
  box-shadow: none;
  border: 1px solid #26282B;
  width: 4rem;
}

#restart-confirm-no-btn {
  box-shadow: none;
  border: 1px solid #26282B;
  width: 4rem;
}