html {
  font-family: "Poppins", sans-serif;
  color: #26282B;
  width: 100%;
  height: 100%;
}

body {
  margin: 0px;
  width: 100%;
  height: 100%;
  background-image: url("/img/pink_flamingo_01.jpg");
  /* background-image: url('/img/pink_angora_01.jpg'); */
  background-size: cover;
}
@media only screen and (max-width: 575px) {
  body {
    background-size: 100%;
  }
}

p {
  font-family: "Faustina", sans-serif;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  width: 100%;
  height: 100%;
}
@media only screen and (max-width: 575px) {
  #root {
    align-items: stretch;
  }
}

button {
  background: #F8F8F8;
  border: none;
  border-radius: 0.4rem;
  outline: none;
  box-sizing: border-box;
  box-shadow: -4px 4px 4px #26282B;
  font-size: 1.5rem;
  cursor: pointer;
}
@media only screen and (min-width: 576px) {
  button {
    -moz-transition: color 0.4s, background-color 0.4s, border-color 0.4s;
    -o-transition: color 0.4s, background-color 0.4s, border-color 0.4s;
    -webkit-transition: color 0.4s, background-color 0.4s, border-color 0.4s;
    transition: color 0.4s, background-color 0.4s, border-color 0.4s;
  }
  button:hover {
    color: #F8F8F8;
    border-color: #385269;
    background-color: #385269;
  }
}
@media only screen and (max-width: 575px) {
  button:active {
    color: #F8F8F8;
    border-color: #385269;
    background-color: #385269;
  }
}

input, input:focus {
  background: #F8F8F8;
  border: none;
  border-radius: 0.4rem;
  outline: none;
  outline-offset: 0;
  box-sizing: border-box;
  box-shadow: -4px 4px 4px #26282B;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}

input[type=text] {
  font-size: 1.5rem;
}